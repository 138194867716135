<template>
  <div class="home">
    <div class="flex top">
      <!-- <div tabindex="1" class="narrow ads" @keydown="menuKeyDown($event)" :style="'background-image: url('+require('@/assets/img/ads.png')+');background-size: 100% 100%;;'">
      </div>
      <div tabindex="1"  @click="next({path:'/webview',query: { path: 'http://launcher.iserver.tv/ytb/index#/'}})" class="narrow ytb" @keydown="menuKeyDown($event)" :style="'background-image: url('+require('@/assets/img/im.png')+');background-size: 100% 100%;'">
        </div> -->
      <!-- <div tabindex="1"  @click="next({path:'/webview',query: { path: 'http://launcher.iserver.tv/ytb/index#/'}})" class="narrow ka" @keydown="menuKeyDown($event)" :style="'background-image: url('+(model==='oem'?require('@/assets/img/ka.jpg'):require('@/assets/img/ads1.png'))+');background-size: 100% 100%;'">
        </div> -->
      <div tabindex="1" id="tiktokCenter" @click="next('/tiktok')" @keydown="appKeyDown($event)" class="narrow ka">Tiktok Karaoke Player</div>
    </div>
    <div class="flex middle">
          <!-- <div class="narrow" tabindex="1" @click="next('/tiktok')" @keydown="appKeyDown($event)">
            <img src="@/assets/img/tiktok.png" alt="">
          </div> -->
          <div class="narrow" tabindex="1" @click="next('/setting')" @keydown="appKeyDown($event)">
            <img src="@/assets/img/setting.png" alt="">
          </div>
          <div class="narrow" tabindex="1" @click="next('/blueTooth')" @keydown="appKeyDown($event)">
            <img src="@/assets/img/BlueTooth.png" alt="">
          </div>
          <div class="narrow" tabindex="1" @click="next('/auxin')" @keydown="appKeyDown($event)">
            <img src="@/assets/img/LINE.png" alt="">
          </div>
          <div class="narrow" tabindex="1" @click="next('/usb')" @keydown="appKeyDown($event)">
            <img src="@/assets/img/usb.png" alt="">
          </div>
          <!-- <div class="narrow" tabindex="1" @click="next('/airplay')" @keydown="appKeyDown($event)">
            <img src="@/assets/img/airplay.png" alt="">
          </div> -->
        </div>
  </div>
</template>
<script>
import { keyMap } from '@/util/keyUtil.js'
export default {
  data () {
    return {
      // eslint-disable-next-line no-undef
      model: APP_MODEL
    }
  },
  created () {
    this.$plugin.send({
      data: [0x55, 0xAA, 0x02, 0x01, 0x00, 0x16]
    })
  },
  mounted () {
    document.getElementsByClassName('narrow')[0].focus()
  },
  methods: {
    next (path) {
      this.$router.push(path)
    },
    menuKeyDown (e) {
      const target = e.target
      const key = keyMap[e.keyCode]
      if (key === 'down') {
        document.getElementsByClassName('middle')[0].children[0].focus()
      }
      if (key === 'ok') {
        target.click()
      } else if (key === 'left') {
        if (target.previousSibling) {
          target.previousSibling.focus()
        }
      } else if (key === 'right') {
        if (target.nextSibling) {
          target.nextSibling.focus()
        }
      }
    },
    appKeyDown (e) {
      const target = e.target
      const key = keyMap[e.keyCode]
      if (key === 'up') {
        document.getElementsByClassName('top')[0].children[0].focus()
      }
      if (key === 'ok') {
        target.click()
      } else if (key === 'left') {
        if (target.previousSibling) {
          target.previousSibling.focus()
        }
      } else if (key === 'right') {
        if (target.nextSibling) {
          target.nextSibling.focus()
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.home{
  background: black;
    position: relative;
    .narrow{
      -webkit-transform: scale(1);
      &:focus{
        -webkit-transform: scale(1.2);
        transition: all 0.2s;
      }
    }
    .flex{
      display:-webkit-flex;
      #tiktokCenter{
        font-size: 40px;
        line-height: 400px;
        text-align: center;
        background-color: rgb(25, 150, 240);
        color: #fff;
      }
    }
    img{
      width:100%
    }
    .middle{
      .narrow{
        margin: 20px 45px;
        // height: 260px;
      }
    }
    .top{
      .ads{
        width: 800px;
        height: 400px;
        margin: 50px;
      }
      .ytb{
        width:480px;
        height:400px;
        margin: 50px;
      }
      .ka{
        width:1000px;
        height: 400px;
        margin: 50px auto ;
      }
    }
}
</style>
